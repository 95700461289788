import React, { useState } from 'react';
import Chatbot from './components/Chatbot/Chatbot';
import Navbar from './components/Navbar/Navbar'
import './Main.css'

function Main() {

  const [userLoc, setUserLoc] = useState(null) //user location variable
  const [authStatus, setAuthStatus] = useState(false) //user location variable

  return (
      <div className="global-container">
        <Navbar authStatusC={authStatus} setAuthStatusC={(newdata) => setAuthStatus(newdata)} userLocC={userLoc} setUserLocC={(newdata) => setUserLoc(newdata)} />
        <Chatbot authStatusC={authStatus} setAuthStatusC={(newdata) => setAuthStatus(newdata)} userLocC={userLoc} setUserLocC={(newdata) => setUserLoc(newdata)} />
      </div>
  );
}

export default Main;
