import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import './Chatbot.css';
import axios from 'axios';
import Markdown from 'react-markdown'
import { AiFillAudio } from "react-icons/ai";
import mbta_logo from '../../images/mbta_logo.png'
import user_logo from '../../images/user_logo.png'
import userpool from '../Auth/Pool';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const Chatbot = ({authStatusC, setAuthStatusC, userLocC, setUserLocC}) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([{ text: "#### MBTA assistant here! I can help with any real-time information you may have about the MBTA. Currently still in training but right now you can ask me some of the following:\n- when a train/bus arrives at station A to station B within the MBTA.\n    - (ex. *\"when is the next bus to ruggles from kenmore?\"*)\n- if there are any service alerts near you or at an MBTA station.\n    - (ex. *\"any service alerts near me?\"*)\n\nYou can also click any of the suggestions below after logging in.", user: false }]);
  const messagesEndRef = useRef(null)

  const [defaultMsg1, setMsg1] = useState("Next train from North Station to Chelsea?")
  const [defaultMsg2, setMsg2] = useState("Service alerts near me?")
  const [defaultMsg3, setMsg3] = useState("What is the bus from Kenmore to Ruggles?")

  const [queueToCall, setQueueToCall] = useState(false)

  const userName = userpool.getCurrentUser() ? userpool.getCurrentUser().username : null;

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const startListening = () => SpeechRecognition.startListening({ continuous: true });

  const AudioInput = () => {
    if (listening) {
      //console.log(`Transcript: ${transcript}`)
      SpeechRecognition.stopListening();
      if (!transcript.trim()) return;
      setInput(transcript);
      resetTranscript();
      

    } else {
      startListening();
    }
  }

  useLayoutEffect(()=> {
      const fetchUserData = async () => {
        if (!authStatusC) {
          //console.log("No user logged in from Chatbot comp.")
        } else {
          if (userName) {
          const res = await axios.post(process.env.REACT_APP_API_GET_USER_ENTRIES, {
            user_email: userName
          }) // get chatbot response
          if (Array.isArray(res.data.data.data)) {
            const user_entries_length = res.data.data.data.length
            if (0 < user_entries_length) {
              setMsg1(res.data.data.data[0])
            }
            if (1 < user_entries_length) {
              setMsg2(res.data.data.data[1])
            }
            if (2 < user_entries_length) {
              setMsg3(res.data.data.data[2])
            }
          }
        }}
      };
      fetchUserData();
    }, [authStatusC, queueToCall, userName]);

  const chatWithBot = async (userInput) => {
    if (!userName) {
      // console.log("No user logged yet while chatting.")
      return "Please make sure to log in first before using the chatbot!"
    }

    const res = await axios.post(process.env.REACT_APP_API_CHATBOT, {
      user_prompt: userInput,
      user_location: userLocC,
      user_email: userName
    }) // get chatbot response

    if (res.data.return_status === 1) { // output chatbot response if success
      return res.data.data.response;
    } else {
      return "Sorry! Unable to to get a response at the moment. Please come back in a few minutes!"
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth"})
  }

  const clearMessages = () => {
    setMessages([{ text: "#### MBTA assistant here! I can help with any real-time information you may have about the MBTA. Currently still in training but right now you can ask me some of the following:\n- when a train/bus arrives at station A to station B within the MBTA.\n    - (ex. *\"when is the next bus to ruggles from kenmore?\"*)\n- if there are any service alerts near you or at an MBTA station.\n    - (ex. *\"any service alerts near me?\"*)\n\nYou can also click any of the suggestions below after logging in.", user: false }]);
  }


  useEffect(() => {
    scrollToBottom()
  },)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    const userMessage = { text: input, user: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    const aiMessage = { text: 'Checking...', user: false };
    setMessages((prevMessages) => [...prevMessages, aiMessage]);

    const response = await chatWithBot(input);
    const newAiMessage = { text: response, user: false };
    setMessages((prevMessages) => [...prevMessages.slice(0, -1), newAiMessage]);
    setInput('');
    setQueueToCall(!queueToCall);
    resetTranscript();
  };

  return (
    <div className="chatbot-container">

      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div 
            markdown="1"
            key={index}
            className={`message ${message.user ? 'user-message' : 'ai-message'}`}
          >
            {message.user ? <img src={user_logo} alt="user_logo" height="30" width="30" /> : <img src={mbta_logo} alt="mbta_logo" height="30" width="30" />}
            <Markdown children={message.text} />
          </div>
        ))}

        <div ref={messagesEndRef} />
      </div>


      <div className="input-global">
        <form id="input-form" className="chatbot-input-form" onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              value={input}
              maxLength="50"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Your message..."
            />
            <div className="inputBtns">
              <div className="sendBtnContainer">
                <button className="sendBtn" type="submit">Send</button>
              </div>
              {browserSupportsSpeechRecognition ? 
              <div className="audioBtnContainer">
                <button
                  onClick={AudioInput}
                  className={listening?"audioBtnHold":"audioBtn"}
                ><AiFillAudio /></button>
              </div> : null }
            </div>
          </div>
        </form>

        <div className="prev-button-inputs">
          <button form="input-form" onClick={() => setInput(defaultMsg1)}>{defaultMsg1}</button>
          <button form="input-form" onClick={() => setInput(defaultMsg2)}>{defaultMsg2}</button>
          <button form="input-form" onClick={() => setInput(defaultMsg3)}>{defaultMsg3}</button>
        </div>

      </div>

      <div className="clear-btn-container">
        <button className="chatbot-clear-btn" type="submit" onClick={clearMessages}>Clear History</button>
      </div>

    </div>
  );
};
export default Chatbot;

