import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import userpool from './Pool';
import { ThreeCircles } from 'react-loader-spinner';

const Register = ({setAuthStatusC, setAuthMethodC}) => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [pwd, setPwd] = useState('');
	const [rpwd, setRPwd] = useState('');
	const [successmsg, setSuccessMsg] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleRegister = async (e) => {
		e.preventDefault()
		setError('');
		if ((email === "") || (pwd === "")){
			e.preventDefault();
			setError("Please enter a username/email, name, and password.")
			return 
		} else {
			setError()
		}

		if (pwd !== rpwd) {
			e.preventDefault();
			setError("Make sure passwords match.")
			return
		} else {
			setError()
		}

		setLoading(true);

		const attributeList = [];
	    attributeList.push(
	        new CognitoUserAttribute({
	          Name: 'given_name',
	          Value: name,
	        })
	    );
	    let username = email;
	    userpool.signUp(username, pwd, attributeList, null, async (err, data) => {
	        if (err) {
	          // console.log("Couldn't sign up");
	          // console.log(err);
	          e.preventDefault()
	          setLoading(false);
	          setError(err.message)
	          return
	        } else {
	          	// console.log('User Added Successfully');
	      	  	const res = await axios.post(process.env.REACT_APP_API_REGISTER_URL, {
			        user_email: username,
			        user_name: name,
			        user_pwd: pwd
			    })
	      	  	// console.log(res.data)
	      	  	setLoading(false);
		      	if (res.data.return_status === 1) {
		      		if (res.data.data.status === 1) {
		      			e.preventDefault()
		      			setEmail('')
		      			setName('')
		      			setPwd('')
		      			setRPwd('')
		      			setSuccessMsg("Account created! Log in to continue.")
		      			return
		      		} else if (res.data.data.status === 0) {
		      			e.preventDefault()
		      			setError(res.data.data.message) //error with saving data to DB
		      			return
		      		}
		      	}
	        }
	    });

	};

	return (
		<>	{loading? 
			<div className="loadingIconContainer">
				<ThreeCircles color="#0A5200" height="60" width="60" radius="10" wrapperClass="loadingIcon"/>
			</div>:

			<div className="register-form">
				<p className="register-form_title">Sign up</p>
				<form className="register-input-form" onSubmit={handleRegister}>
					<input
					type="text"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="username or email"
					/>
					<input
					type="text"
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder="your name"
					/>
					<input
					type="password"
					value={pwd}
					onChange={(e) => setPwd(e.target.value)}
					placeholder="password"
					/>
					<input
					type="password"
					value={rpwd}
					onChange={(e) => setRPwd(e.target.value)}
					placeholder="repeat password"
					/>
					<div>
						<button type="submit">Register</button>
					</div>
				</form>
				 {successmsg?<label className="success_msg_txt">{successmsg}</label>:null}
				 {error?<label className="error_msg_txt">{error}</label>:null} 
				<div>
					<p> Already have an account? 
						<label className="go-to-login-link" onClick={() => setAuthMethodC(true)}> Log In </label>
					</p>
				</div>
			</div> }
		</>
	);
};
export default Register;