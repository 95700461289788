import React, { useState, useLayoutEffect} from 'react';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import userpool from './Pool';

const Auth = ({authStatusCC, setAuthStatusCC}) => {
	const [authMethod, setAuthMethod] = useState(true);
	const user = userpool.getCurrentUser();

	useLayoutEffect(() => {
		if (!user) {
			setAuthStatusCC(false);
		} else {
			setAuthStatusCC(true);
		}
    }, [authStatusCC, setAuthStatusCC, user]);

	// auth_method = true == login
	// auth_method = false == register
	return (
		<div>
			{authStatusCC ? 
			<Home authStatusC={authStatusCC} setAuthStatusC={(newstatus) => setAuthStatusCC(newstatus)} /> 
			:
			<>
			{authMethod 
				? <Login setAuthStatusC={(newstatus) => setAuthStatusCC(newstatus)} setAuthMethodC={(newstatus) => setAuthMethod(newstatus)} />
			 : <Register setAuthStatusC={(newstatus) => setAuthStatusCC(newstatus)} setAuthMethodC={(newstatus) => setAuthMethod(newstatus)} />
			}
			</>
				

			}
		</div>
	);
};


export default Auth;