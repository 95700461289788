import './App.css';
import { BrowserRouter } from 'react-router-dom'
import Main from './Main'

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Main/>
      </div>
    </BrowserRouter>
  );
}

export default App;


//Navbar colors
//background: 10160F 
// inputs: #282A28
// buttons: background-color: #0A5200;
// button: hover: 052700
//input txt color: color: #8F8F8F;


//text link color: #0067B7;
//text link color hover: 00355F;
