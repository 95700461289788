import React, { useState } from 'react';
import './Login.css';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from './Pool';
import { ThreeCircles } from 'react-loader-spinner';

const Login = ({setAuthStatusC, setAuthMethodC}) => {
	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [error,setError]=useState();
	const [loading, setLoading] = useState(false);

	const handleLogin = (e) => {
		e.preventDefault()
		if ((email === "") || (pwd === "")){
			e.preventDefault();
			setError("Please enter your email and password.")
			return 
		} else {
			setError()
		}
		const user =new CognitoUser({
            Username: email,
            Pool: userpool
        });

        const authDetails= new AuthenticationDetails({
            Username: email,
            Password: pwd
        });

        setLoading(true);

        user.authenticateUser(authDetails, {
            onSuccess:(result)=>{
                // console.log("login successful");
                setLoading(false);
                setAuthStatusC(true);
            },
            onFailure:(err)=>{
                // console.log("login failed");
                // console.log(err);
                setError(err.message);
                setLoading(false);
            }
        });
	};

	return (
		<> {loading? 
		<div className="loadingIconContainer">
			<ThreeCircles color="#0A5200" height="60" width="60" radius="10" wrapperClass="loadingIcon"/>
		</div>:

		<div className="login-form">
			<p className="login-form_title">Log In</p>
			<form className="login-input-form" onSubmit={handleLogin}>
				<input
				type="text"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder="username or email"
				/>
				<input
				type="password"
				value={pwd}
				onChange={(e) => setPwd(e.target.value)}
				placeholder="password"
				/>
				<div>
					<button type="submit">Log In</button>
				</div>
			</form>

			{error?<label className="error_msg_txt">{error}</label>:null} 
			<div>
				<p> Create an account?
					<label className="go-to-register-link" onClick={() => setAuthMethodC(false)}> Sign up </label>
				</p>
			</div>
		</div> }
		</>
	);
};

export default Login;