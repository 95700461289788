import React, { useState, useLayoutEffect } from 'react';
import './Home.css';
import userpool from './Pool';

const Home = ({authStatusC, setAuthStatusC}) => {

	const [userName, setUserName] = useState('')
	const user = userpool.getCurrentUser();

	const handleLogout = (e) => {
    	user.signOut();
		setAuthStatusC(false);
		// console.log("Logged out!")
	};

	useLayoutEffect(()=> {
		const fetchUserData = async () => {
			if (user) {
				user.getSession(function(err, session) {
					if (err) {
						// console.log("Session error")
						// console.log(err);
						return;
					}
					// NOTE: getSession must be called to authenticate user before calling getUserAttributes
					user.getUserAttributes(function(err, attributes) {
						if (err) { // Handle error
							// console.log("User attributes error")
							// console.log(err)
						} else {
							setUserName(attributes[0]['Value'])
						}
					});
				});
			};
		};
		if (userName === '') {
			fetchUserData()
		}

	}, [authStatusC, user, userName])

	return (
		<div>
			<div>
				<button className="logout-btn" onClick={handleLogout}>Log out</button>
			</div>
			<h1> Welcome back {userName}!</h1>
		</div>
	);
};

export default Home;