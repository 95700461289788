import React, { useState, useRef, useEffect } from 'react';
import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons";
import { Grid } from 'react-loader-spinner';
import Auth from '../Auth/Auth';
import './Navbar.css';
import app_logo from '../../images/app_logo.png'

const Navbar = ({authStatusC, setAuthStatusC, userLocC, setUserLocC}) => {

	const [sidebar, setSidebar] = useState(false);
	const [accessLoc, setAccessLoc] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);
	const [isError, setIsError] = useState(false);
	const wrapperRef = useRef(null);

	const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
		setSidebar(false);
      }
      else {
      	setSidebar(true)
      }
    };

    const handleAccessLocation = async () => {
 
    	if (accessLoc === false) { // if false, user is clicking to be true
    		setIsWaiting(true)
    		if (navigator.geolocation) {
		      navigator.geolocation.getCurrentPosition(success, error);
		    } else {
		      console.log("Geolocation not supported");
		    }
    	} else {
    		setUserLocC(null);
    		setAccessLoc(false);
    		// console.log("Removed user location.")
    	}
    }

    const success = (position) => {
    	const latitude = position.coords.latitude;
    	const longitude = position.coords.longitude;
    	setIsError(false);
	    setUserLocC({ latitude, longitude });
	    setIsWaiting(false);
	    setAccessLoc(true);
	    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
	};

	 const error = () => {
    	console.log("Unable to retrieve your location");
    	setIsError(true);
    	setIsWaiting(false);
  	};

	// below is the same as componentDidMount and componentDidUnmount
    useEffect(() => {
	    document.addEventListener("mousedown", handleClickOutside, false);
	    return () => {
	      document.removeEventListener("mousedown", handleClickOutside, false);
	    };
    }, []);

	return (
	    <>
	      <div ref={wrapperRef} >
	      <IconContext.Provider value={{ color: "undefined" }}>
	      
	        <div className="navbar">
	          <div className="menu-bars" >
	            <FaIcons.FaBars className="menu-bars-icon" onClick={() => setSidebar(true)} />
	          </div>
	        </div>
	  
	        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
	          <div className="inner-navbar">
		          <div className="inner-navbar-menu-bars">
		            <FaIcons.FaBars className="inner-navbar-menu-bars-icon" onClick={() => setSidebar(false)} />
		          </div>
	          </div>


	          <div>
	          	<img src={app_logo} alt="app_logo" height="100" width="120" />
	          </div>
	          
	          <Auth authStatusCC={authStatusC} setAuthStatusCC={(newdata) => setAuthStatusC(newdata)} />

	          <div className="navbar-utils">
		          <div>
		          	{isWaiting?
		          	<div className="access-loc-waiting-container"> 
			          	<Grid height="25" width="25"/> 
			          	<label>Getting your location </label> 
		          	</div>:null} 
		          </div>


		          <div className="access-loc-container">
		          	{isError?<div className="access-loc-error"> Error getting your location. Make sure to enable location permissions for this webpage </div>:null}
		          	
		          	<div className="access-loc-inputs">
			          	<div>
					        <input
					          type="checkbox"
					          checked={accessLoc}
					          disabled={isWaiting}
					          onChange={handleAccessLocation}
					        />
				        </div>

				        <div className="access-loc-label">
				        	<label className="access-loc-label"> {accessLoc? <>Location set!</>:<>Access my location</>}</label>
				        </div>
			        </div>
			      	
			      </div>


			      <div className="projectInfo">
			          <div className="p-disclaimer">
			            <p> <FaIcons.FaExclamationCircle className="disclaimer-icon"/> Note: This project is not affiliated with the MBTA.</p>
			          </div>
			          <div className="p-about">
			            <p>Written by Kevin Delgado. Feel free to email me at thekevry@gmail.com for any questions or issues!</p>
			          </div>
		          </div>
	          </div>
	        </nav>
	      </IconContext.Provider>
	      </div>

	    </>
	);

}

export default Navbar;